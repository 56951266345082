import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';

const BarraAdmin = () => {
    return (
      <Navbar bg="light" expand="lg" >
      <Container>
        <Navbar.Brand href="#home">Administrador</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink to="/adminreceptores" className="nav-link" activeclassname="active">
              Gestionar Receptores
            </NavLink>
            <NavLink to="/adminnoticias" className="nav-link" activeclassname="active">
              Gestionar Noticias
            </NavLink>
            <NavLink to="/admincarrusel" className="nav-link" activeclassname="active">
              Gestionar Carrusel
            </NavLink>
            <NavLink to="/adminnosotros" className="nav-link" activeclassname="active">
              Gestionar Nosotros
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    )
}

export default BarraAdmin;