import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button } from 'react-bootstrap';
import logo from '../img/logo.png';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate desde react-router-dom
import { useAuth } from '../AuthContext';
import { API_ROUTES } from '../config';
import { HashLink as Link } from 'react-router-hash-link';
import Swal from 'sweetalert2';
const Barra = () => {
  const navigate = useNavigate();
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = async () => {
    setIsLoggingOut(true);

    try {
      // Cierra la sesión en el backend
      const response = await axios.post(API_ROUTES.logout);
      //console.log('Sesión cerrada con éxito. Respuesta:', response);

      // Muestra SweetAlert de éxito y redirige al inicio después de cerrar el modal
      Swal.fire({
        icon: 'success',
        title: '¡Sesión cerrada!',
        text: 'Redirigiendo al inicio.',
        timer: 3000, // Cierra automáticamente después de 3 segundos
        timerProgressBar: true, // Muestra una barra de progreso del temporizador
        showConfirmButton: false, // No muestra el botón "OK"
      }).then(() => {
        setIsLoggedIn(false); // Actualiza el estado después de cerrar sesión
        setIsLoggingOut(false);
        navigate('/'); // Redirige al inicio después de cerrar sesión
      });
    } catch (error) {
      console.error('Error al cerrar sesión:', error);

      // Muestra SweetAlert de error y cierra el modal después de cerrar el modal
      Swal.fire({
        icon: 'error',
        title: 'Error al cerrar sesión',
        text: 'Por favor, intenta de nuevo más tarde.',
      }).then(() => {
        setIsLoggingOut(false);
      });
    }
  };

  return (
    <div className="fondo-barra" style={{ position: 'sticky', top: 0, zIndex: 100 }}>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} alt="Logo Asociacion" style={{ height: '40px' }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              {/* Mostrar solo si el usuario está autenticado */}
              {isLoggedIn ? (
                <>
                  <NavLink className='nav-link d-flex align-items-center' to='/' style={{ color: 'white' }}>Inicio</NavLink>
                  <Link smooth to="/#nosotros" className='nav-link d-flex align-items-center' style={{ color: 'white' }}>Nosotros</Link>
                  <Link smooth to="/#noticias" className='nav-link d-flex align-items-center' style={{ color: 'white' }}>Noticias</Link>
                  <NavLink className='nav-link d-flex align-items-center' to='/receptores' style={{ color: 'white' }}>Receptores</NavLink>
                  <Link smooth to="/#contacto" className='nav-link d-flex align-items-center'><Button className="btn btn-secondary">Contacto</Button></Link>
                  <NavLink className='nav-link d-flex align-items-center'>
                    {isLoggingOut ? 'Cerrando sesión...' : <Button className="btn btn-secondary" onClick={handleLogout}>Cerrar sesión</Button>}
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink className='nav-link d-flex align-items-center' to='/' style={{ color: 'white' }}>Inicio</NavLink>
                  <Link smooth to="/#nosotros" className='nav-link d-flex align-items-center' style={{ color: 'white' }}>Nosotros</Link>
                  <Link smooth to="/#noticias" className='nav-link d-flex align-items-center' style={{ color: 'white' }}>Noticias</Link>
                  <NavLink className='nav-link d-flex align-items-center' to='/receptores' style={{ color: 'white' }}>Receptores</NavLink>
                  <Link smooth to="/#contacto" className='nav-link d-flex align-items-center'><Button className="btn btn-secondary">Contacto</Button></Link>
                  <NavLink className='nav-link d-flex align-items-center' to='/login'><Button className="btn btn-secondary">Login</Button></NavLink>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Barra;