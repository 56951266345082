import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'; // Importamos useNavigate desde react-router-dom
import { API_ROUTES } from "../../config";
import { FaEdit } from "react-icons/fa";


const CompShowCarrusel = () => {
  const [carrusels, setCarrusels] = useState([]);
  

  useEffect(() => {
    getCarrusels();
  }, []);

  const getCarrusels = async () => {
    try {
      const res = await axios.get(API_ROUTES.carrusels);
      setCarrusels(res.data);
    } catch (error) {
      console.error('Error al obtener carrusel:', error);
    }
  };


  return (
    <div className='container mb-3 seccionGestionarCarrusel'>
       <div className='text-center tituloTablas'><h4>Gestionar Carrusel</h4></div>
      <div className='container mb-3'>
        <div className='row'>
          <div className='col'>
            <Table striped bordered hover responsive="sm">
              <thead className='table-secondary'>
                <tr>
                  <th>Posicion</th>
                  <th>Titulo</th>
                  <th>Subtitulo</th>
                  <th>Imagen</th>
                  <th>Acciones</th>
                </tr>
              </thead>

              <tbody>
                {carrusels.map((carrusel) => (
                  <tr key={carrusel.id}>
                    <td>{carrusel.id}</td>
                    <td>{carrusel.titulo}</td>
                    <td>{carrusel.subtitulo}</td>
                    <td>
                      {carrusel.img && (
                        <img
                          src={`${API_ROUTES.carruselsUploads}${carrusel.img}`}
                          alt={`Imagen de ${carrusel.img}`}
                          style={{ maxWidth: '150px' }}
                        />
                      )}
                    </td>
                    <td className='estilo-accion'>
                      <Link
                        to={`/editcarrusel/${carrusel.id}`}
                        className='btn btn-info'
                        style={{ width: '103px' }}
                        
                      >
                        <FaEdit/>
                        Editar
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CompShowCarrusel;