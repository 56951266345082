import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import { API_ROUTES } from '../config';



const Banner = () => {
  const [carrusels, setCarrusels] = useState([]);

  useEffect(() => {
    getCarrusels();
  }, []);

  const getCarrusels = async () => {
    try {
      const res = await axios.get(API_ROUTES.carrusels);
      setCarrusels(res.data);
    } catch (error) {
      console.error('Error al obtener carrusels:', error);
    }
  };

  return (
    <Carousel className='carousel-container'>
      {carrusels.map((carrusel, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100"
            src={`${API_ROUTES.carruselsUploads}${carrusel.img}`}
            style={{ height: '700px' }}
            alt={`Carrusel ${index + 1}`}
          />
          <Carousel.Caption className='carrusel'>
            <h2>{carrusel.titulo ? carrusel.titulo : ''}</h2>
            <p>{carrusel.subtitulo ? carrusel.subtitulo : ''}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Banner;