import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate desde react-router-dom
import { useAuth } from '../AuthContext';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { API_ROUTES } from '../config';



const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const { isLoggedIn, setIsLoggedIn, AlertaExitosa, AlertaErrorLogin } = useAuth(); // Obtenemos el estado y la función desde el contexto
  /*
  useEffect(() => {
    const storedAuth = localStorage.getItem('isLoggedIn');
    if (storedAuth === 'true') {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn]);
*/
  const handleLogin = async (e) => {
    e.preventDefault();
    AlertaExitosa();

    try {
      const response = await axios.post(API_ROUTES.login, {
        username,
        password,
      });

      // La solicitud fue exitosa, podríamos redirigir al usuario a otra página
      //console.log(response.data.message);
      setIsLoggedIn(true); // Actualizamos el estado isLoggedIn a true para indicar que el usuario está autenticado
      //localStorage.setItem('isLoggedIn', 'true'); // Almacena el estado de autenticación en localStorage
      navigate('/adminreceptores');
    } catch (error) {
      AlertaErrorLogin();
      // Error en la solicitud, mostrar el mensaje de error
      setError(error.response.data.message);
    }
  };

  return (
    <div>
    <Container>
      <Row className="vh-100 d-flex justify-content-center align-items-center">
        <Col md={8} lg={6} xs={12}>
          <div className="border border-3 border-secondary"></div>
          <Card className="shadow">
            <Card.Body>
              <div className="mb-3 mt-md-4">
                <h2 className="fw-bold mb-2 text-uppercase ">ACCESO</h2>
                <p className=" mb-5">¡Por favor, introduce tu usuario y contraseña!</p>
                {error && <p>{error}</p>}
                <div className="mb-3">
                  <Form onSubmit={handleLogin}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="text-center">
                      Usuario
                      </Form.Label>
                      <Form.Control type="text" placeholder="Correo" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="formBasicPassword"
                    >
                      <Form.Label>Contraseña</Form.Label>
                      <Form.Control type="password" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </Form.Group>
                    <div className="d-grid">
                      <Button variant="secondary" type="submit">
                        Login
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
  );
};

export default Login;

