import React from 'react'
import Nosotros from '../components/Nosotros'
import Banner from '../components/Banner'
import GaleriaNoticias from '../components/GaleriaNoticias'
import Contacto from '../components/Contacto'
import { useEffect } from 'react'




const Home = () => {

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div >
      <>
        <Banner></Banner>
        <Nosotros></Nosotros>
        <GaleriaNoticias></GaleriaNoticias>
        <Contacto></Contacto>
      </>
    </div>
  )
}
export default Home