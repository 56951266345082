import React from 'react'
import error from '../img/404.svg';

const Error404 = () => {
  return (
    <div style={{padding:'20px'}}>
        <img
          className="d-block w-100"
          src={error} style={{height:'700px'}}
          alt="404"
        />
        
    </div>
  )
}

export default Error404