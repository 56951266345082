import React from 'react'
import logo from '../img/logo.png';

const Footer = () => {
    return (

        <div className='footer'>
            <div className='footer-text'>Desarrollado por Smartinfo Ltda.</div>
            <div className='footer-logo'><img src={logo} alt="Logo Asociacion" style={{ height: '40px' }} /></div>
            <div className='footer-social-icons'>Tel: +5620000000</div>
        </div>

    )
}

export default Footer