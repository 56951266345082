import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { API_ROUTES } from '../../config';
import { FaSave } from 'react-icons/fa';
import { useAuth } from '../../AuthContext';
import { useForm } from 'react-hook-form';




const CompEditNoticia = () => {
    

    const navigate = useNavigate();
    const { noticiaId } = useParams();
    const { AlertaExitosaEditar, AlertaError } = useAuth();
    const { register, handleSubmit, formState: { errors}, setValue, watch } = useForm();
    //const [titulo, setTitulo] = useState('');
    const titulo = watch('titulo');
    const detalle = watch('detalle');
    //const [detalle, setDetalle] = useState('');
    const [estado, setEstado] = useState('');
    const [noticiaImg, setNoticiaImg] = useState(null);
    const [noticiaImgActual, setNoticiaImgActual] = useState('');
    const [contadorCaracteres, setContadorCaracteres] = useState(0);

    
    useEffect(() => {
        // Obtener detalles de la noticia por su ID
        axios.get(`${API_ROUTES.noticias}${noticiaId}`)
            .then((response) => {
                const { data } = response;
                setValue('titulo', data.titulo);
                // Procesar el detalle para convertir saltos de línea HTML en saltos de línea regulares
                const detalleConSaltosHTML = data.detalle.replace(/<br\s*\/?>/gi, '\n');
                setValue('detalle', detalleConSaltosHTML);
                setEstado(data.estado);
                setNoticiaImgActual(`${API_ROUTES.noticiasUploads}${data.noticia_img}`);
                setContadorCaracteres(detalleConSaltosHTML.length);
            })
            .catch((error) => {
                console.error('Error al obtener detalles de la noticia:', error);
            });
    }, [noticiaId, setValue]);


      useEffect(() => {
        if (detalle) {
          
          const detalleSinSaltosHTML = detalle.replace(/<br\s*\/?>/gi, '\n');
    
          const caracteresContados = detalleSinSaltosHTML.length + (detalleSinSaltosHTML.match(/\n/g) || []).length;
          setContadorCaracteres(caracteresContados);
        }
      }, [detalle]);

    

    const enviar = async (e) => {
        //e.preventDefault();
        AlertaExitosaEditar();

        const formData = new FormData();
        formData.append('titulo', titulo);
        formData.append('detalle', detalle);
        formData.append('estado', estado);
        if (noticiaImg) {
            formData.append('noticia_img', noticiaImg);
        }


        try {
            await axios.put(`${API_ROUTES.noticias}${noticiaId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Noticia editada correctamente');
            navigate('/adminnoticias');
        } catch (error) {
            AlertaError();
            console.error('Error al editar noticia:', error);
        }
    };

    const seleccionarEstado = (event) => {
        setEstado(event.target.value);
    };

    const seleccionarImagen = (event) => {
        setNoticiaImg(event.target.files[0]);
    };
    

    

    return (
        <div className="container" style={{ marginTop: '80px', marginBottom: '80px' }}>
            <h3>Editar Noticia</h3>
            <form onSubmit={handleSubmit(enviar)} encType="multipart/form-data">
                <div className="mb-3">
                    <label className="form-label">Titulo</label>
                    <input
                        placeholder='Ingrese un titulo'
                        //value={titulo}
                        type="text"
                        className="form-control"
                        maxLength="100"
                        {...register("titulo", { required: true, minLength: 4, maxLength: 100 })}
                        //onChange={(e) => setValue(e.target.value)}
                    />
                    {errors.titulo && <p className='Msjvalidaciones'>Ingrese un título válido (entre 4 y 100 caracteres).</p>}
                </div>
                <div className="mb-3">
                    <label className="form-label">Detalle</label>
                    <textarea
                        placeholder='Ingrese el detalle de la noticia'
                        type="text"
                        className="form-control"
                        maxLength="5000"
                        //value={detalle}
                        {...register("detalle", { required: true, minLength: 20, maxLength: 5000 })}
                        style={{height:'300px'}}
                       
                        rows="4"
                    />
                    {errors.detalle && <p className='Msjvalidaciones'>Ingrese el detalle (entre 20 y 5000 caracteres).</p>}
                    <div id='contadorCaracteres'>{contadorCaracteres}/5000 Max caracteres</div>
                </div>
                <div className="mb-3">
                    <label className="form-label">Estado</label>
                    <div>
                        <label class="form-check-label">
                            <input
                                style={{margin:'5px'}}
                                class="form-check-input"
                                type="radio"
                                value="importante"
                                checked={estado === 'importante'}
                                onChange={seleccionarEstado}
                            />
                            IMPORTANTE
                        </label>
                        <label class="form-check-label">
                            <input
                                style={{margin:'5px'}}
                                class="form-check-input"
                                type="radio"
                                value="comunicado"
                                checked={estado === 'comunicado'}
                                onChange={seleccionarEstado}
                            />
                            COMUNICADO
                        </label>
                        <label class="form-check-label">
                            <input 
                                style={{margin:'5px'}}
                                class="form-check-input"
                                type="radio"
                                value="información"
                                checked={estado === 'información'}
                                onChange={seleccionarEstado}
                            />
                            INFORMACIÓN
                        </label>
                    </div>
                </div>
                <div className="mb-3">
                    <label className="form-label">Imagen Actual</label>
                    {noticiaImgActual && (
                        <div className="mt-2">
                            <img src={noticiaImgActual} alt="Imagen actual de la noticia" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                        </div>
                    )}
                </div>
                <div className="mb-3">
                    <label className="form-label">Nueva Imagen</label>
                    <input type="file" name="noticia_img" id="noticia_img" onChange={seleccionarImagen}  accept=".jpg, .jpeg, .png"  />
                    {noticiaImg && (
                        <div className="mt-2">
                            <p>Imagen seleccionada:</p>
                            <img src={URL.createObjectURL(noticiaImg)} alt="Imagen de la noticia" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                        </div>
                    )}
                </div>
                <div className="text-center">
                    <button type="submit" className="btn btn-success btnGuardar">
                        <FaSave /> Guardar
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CompEditNoticia;