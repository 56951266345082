import React, { useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import contacto from '../img/contacto.jpg';
import { API_ROUTES } from '../config';
import { useAuth } from '../AuthContext';

const Contacto = () => {
  const { AlertaExitosaContacto, AlertaErrorContacto } = useAuth();
  const [nombre, setNombre] = useState('');
  const [correo, setCorreo] = useState('');
  const [detalle, setDetalle] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Estado para controlar la carga

  const handleNombreChange = (event) => {
    setNombre(event.target.value);
  };

  const handleCorreoChange = (event) => {
    setCorreo(event.target.value);
  };

  const handleDetalleChange = (event) => {
    setDetalle(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true); // Iniciar la carga

    try {
      const response = await axios.post(API_ROUTES.correoContacto, {
        nombre,
        correo,
        detalle,
      });

      console.log(response.data.message);
      // Aquí puedes mostrar un mensaje de éxito o realizar alguna acción adicional después de enviar el correo.
      AlertaExitosaContacto();

      setNombre('');
      setCorreo('');
      setDetalle('');

    } catch (error) {
      AlertaErrorContacto();
      console.error('Error al enviar el correo:', error);
      // Aquí puedes mostrar un mensaje de error o realizar alguna acción en caso de error.
    } finally {
      setIsLoading(false); // Detener la carga después de enviar el correo
    }
  };


  return (
    <section id='contacto' className='contacto'>
      <div className="container contactoSec">
        <h1 className='titulo-contacto'>CONTACTO</h1>
        <h6 className='subtituloNoticias' >Para comunicarte con nosotros puedes dejarnos un mensaje.</h6>
        <div className="row">
          {/* Columna izquierda para el formulario */}
          <div className="col-md-6 form-contacto">
            <Form className='formulario' onSubmit={handleSubmit}>
              <Form.Group className='mb-3' controlId="formBasicNombre">
                <Form.Control type="text" placeholder="Nombre" value={nombre} onChange={handleNombreChange} required />
              </Form.Group>
              <Form.Group className='mb-3' controlId="formBasicEmail">
                <Form.Control type="email" placeholder="Correo" value={correo} onChange={handleCorreoChange} required />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" placeholder='Mensaje' rows={3} value={detalle} onChange={handleDetalleChange} required />
              </Form.Group>
              <Button variant="secondary" style={{ width: '100%' }} type="submit">
                {isLoading ? <Spinner animation="border" size="sm" /> : 'Enviar'}
              </Button>
            </Form>
          </div>
          {/* Columna derecha para la foto */}
          <div className="col-md-6 contenedor-imagen">
            <div>
              <img className='imagenContacto' src={contacto} alt="Imagen de contacto" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacto;