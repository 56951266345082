import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { API_ROUTES } from "../../config";
import { FaSave } from 'react-icons/fa';
import { useAuth } from '../../AuthContext';
import { useForm } from 'react-hook-form';




const CompEditCarrusel = () => {
    const { AlertaExitosaEditar, AlertaError } = useAuth();
    const navigate = useNavigate();
    const { carruselId } = useParams();
    const { register, handleSubmit, formState: { errors}, setValue, watch } = useForm();
    //const [titulo, setTitulo] = useState('');
    //const [subtitulo, setSubtitulo] = useState('');
    const [carruselImg, setCarruselImg] = useState(null); // Cambiado 'null' a null
    const [carruselImgActual, setCarruselImgActual] = useState('');
    const titulo = watch('titulo');
    const subtitulo = watch('subtitulo');

    

    useEffect(() => {
        // Obtener detalles del carrusel por su ID
        axios.get(`${API_ROUTES.carrusels}${carruselId}`)
            .then((response) => {
                const { data } = response;
                setValue('titulo', data.titulo);
                setValue('subtitulo',data.subtitulo);
                setCarruselImgActual(`${API_ROUTES.carruselsUploads}${data.img}`);
            })
            .catch((error) => {
                console.error('Error al obtener detalles del carrusel:', error);
            });
    }, [carruselId, setValue]);

    const enviar = async (e) => {
        //e.preventDefault();
        AlertaExitosaEditar();

        const formData = new FormData();
        formData.append('titulo', titulo);
        formData.append('subtitulo', subtitulo);

        // Agregar la imagen solo si hay una nueva imagen seleccionada
        if (carruselImg) {
            formData.append('img', carruselImg);
        }

        try {
            await axios.put(`${API_ROUTES.carrusels}${carruselId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            //console.log('Carrusel editado correctamente');
            navigate('/admincarrusel');
        } catch (error) {
            AlertaError();
            console.error('Error al editar carrusel:', error);
        }
    };

    const seleccionarImagen = (event) => {
        setCarruselImg(event.target.files[0]);
    };
    return (
        <div className="container" style={{ marginTop: '80px', marginBottom: '80px' }}>
            <h3>Editar Seccion Carrusel</h3>
            <form onSubmit={handleSubmit(enviar)} encType="multipart/form-data">
                <div className="mb-3">
                    <label className="form-label">Titulo</label>
                    <input
                    placeholder='Ingrese un titulo'
                    value={titulo}
                    maxLength="100"
                    //type="text"
                    className="form-control"
                   // onChange={(e) => setTitulo(e.target.value)}
                    {...register("titulo", { required: true, minLength: 1, maxLength: 100 })}
                    />
                    {errors.titulo && <p className='Msjvalidaciones'>Ingrese un título válido (entre 1 y 100 caracteres).</p>}
                </div>
                <div className="mb-3">
                    <label className="form-label">Subtitulo</label>
                    <input
                        placeholder='Ingrese un titulo'
                        value={subtitulo} 
                        maxLength="100"
                        //onChange={(e) => setSubtitulo(e.target.value)}
                        type="text"
                        className="form-control"
                        {...register("subtitulo", { required: true, minLength: 1, maxLength: 100 })}
                    />
                    {errors.subtitulo && <p className='Msjvalidaciones'>Ingrese un subtitulo válido (entre 1 y 100 caracteres).</p>}
                </div>

                <div className="mb-3">
                    <label className="form-label">Imagen Actual</label>
                    {carruselImgActual && (
                        <div className="mt-2">
                            <img src={carruselImgActual} alt="Imagen actual del carrusel" style={{ maxWidth: '100%', maxHeight: '200px' }} required accept=".jpg, .jpeg, .png" />
                        </div>
                    )}
                </div>
                <div className="mb-3">
                    <label className="form-label">Nueva Imagen</label>
                    <input type="file" name="img" id="img" onChange={seleccionarImagen} />
                </div>
                <div className="text-center">
                    <button type="submit" className="btn btn-success btnGuardar">
                        <FaSave /> Guardar
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CompEditCarrusel;