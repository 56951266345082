import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_ROUTES } from '../config';
import { Button } from 'react-bootstrap';

const Nosotros = () => {
  const [nosotros, setNosotros] = useState([]);
  const [mostrarTextoCompleto, setMostrarTextoCompleto] = useState(false);
  

  useEffect(() => {
    getNosotros();
    //document.querySelector('#nosotros').scrollIntoView({ behavior: 'smooth' });
  }, []);

  const getNosotros = async () => {
    try {
      const res = await axios.get(API_ROUTES.nosotros);
      setNosotros(res.data);
    } catch (error) {
      console.error('Error al obtener datos nosotros:', error);
    }
  };

  return (
    <section id='nosotros' className='nosotros'>
      {nosotros.map((nosotro) => (
        <div className="container" key={nosotro.id}>
          <h1 className='titulo'>NOSOTROS</h1>
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={`${API_ROUTES.nosotrosUploads}${nosotro.img}`} alt="Imagen 1" className="img-fluid imgNosotros" />
            </div>
            <div className="col-md-6 textoNosotros">
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <h2 className='subtituloNosotros'>{nosotro.titulo}</h2>
                <p className='textoDetalle-nosotros' dangerouslySetInnerHTML={{ __html: mostrarTextoCompleto ? nosotro.detalle.replace(/\n/g, '<br>') : nosotro.detalle.substring(0, 1000).replace(/\n/g, '<br>') }}></p>
                {nosotro.detalle.length > 1000 && (
                  <Button variant="secondary"  style={{ border: '0px', fontSize:'10px' }} onClick={() => setMostrarTextoCompleto(!mostrarTextoCompleto)}>
                  {mostrarTextoCompleto ? 'Leer menos' : 'Leer más'}
                </Button>
                )}


              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Nosotros;

