import React, { createContext, useState, useContext } from 'react';
import Swal from 'sweetalert2';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);


  const AlertaExitosa = () => {

    Swal.fire({
      title: 'Sesion Iniciada',
      text: 'Sesion iniciada con exito', 
      icon: 'success',
      showConfirmButton: false,
      timer: 2000 // espera 3 segundos
    });
  }

  const AlertaErrorLogin = () => {

    Swal.fire('Error', 'Correo o contraseña incorrecto', 'error');
  }


  const AlertaExitosaEditar = () => {

    Swal.fire({
      title: 'Edición Correcta',
      text: 'Se ha realizado la edición exitosamente', 
      icon: 'success',
      showConfirmButton: false,
      timer: 2000 // espera 3 segundos
    });
  }

  const AlertaExitosaCrear = () => {

    Swal.fire({
      title: 'Creación Exitosa',
      text: 'Se ha realizado la creación exitosamente', 
      icon: 'success',
      showConfirmButton: false,
      timer: 2000 // espera 3 segundos
    });
  }

  const AlertaExitosaEliminar = () => {

    Swal.fire({
      title: 'Eliminado',
      text: 'Se ha eliminado exitosamente', 
      icon: 'success',
      showConfirmButton: false,
      timer: 2000 // espera 3 segundos
    });
  }

  const AlertaExitosaContacto = () => {

    Swal.fire({
      title: 'Contacto Enviado',
      text: 'Se ha enviado el correo con exito', 
      icon: 'success',
      showConfirmButton: false,
      timer: 2000 // espera 3 segundos
    });
  }

  const AlertaErrorContacto = () => {

    Swal.fire('Error', 'No se pudo enviar el contacto', 'error');
  }

  const AlertaError = () => {

    Swal.fire('Error', 'No se puedo guardar', 'error');
  }

 
  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, AlertaExitosa, AlertaExitosaEditar, AlertaExitosaCrear, AlertaExitosaEliminar, AlertaErrorLogin, AlertaExitosaContacto, AlertaErrorContacto, AlertaError }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);



