const API_BASE_URL = 'https://sitioreceptores.smartinfo.cl';



export const API_ROUTES = {

    carrusels:`${API_BASE_URL}/receptoresdatos/carrusels/`,
    carruselsUploads:`${API_BASE_URL}/receptoresdatos/uploads/carrusel/`,
    nosotros:`${API_BASE_URL}/receptoresdatos/nosotros/`,
    nosotrosUploads:`${API_BASE_URL}/receptoresdatos/uploads/nosotros/`,
    noticias:`${API_BASE_URL}/receptoresdatos/noticias/`,
    noticiasNuevas:`${API_BASE_URL}/receptoresdatos/nuevanoticia/`,
    noticiasUploads:`${API_BASE_URL}/receptoresdatos/uploads/noticias/`,
    receptores:`${API_BASE_URL}/receptoresdatos/`,
    receptoresRegiones:`${API_BASE_URL}/receptoresdatos/regiones/`,
    receptoresComunas:`${API_BASE_URL}/receptoresdatos/comunas/`,
    receptoresConComunas:`${API_BASE_URL}/receptoresdatos/receptores-with-comunas/`,
    receptoresUploads:`${API_BASE_URL}/receptoresdatos/uploads/receptores/`,
    logout:`${API_BASE_URL}/receptoresdatos/logout/`,
    login:`${API_BASE_URL}/receptoresdatos/login/`,
    correoContacto:`${API_BASE_URL}/receptoresdatos/enviar-correo/`,

};