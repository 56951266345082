import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Modal } from 'react-bootstrap';
import { Link} from 'react-router-dom'; // Importamos useNavigate desde react-router-dom
import { API_ROUTES } from "../../config";
import { FaEdit } from "react-icons/fa";



const CompShowNosotros = () => {
  const [nosotros, setNosotros] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedNosotros, setSelectedNosotros] = useState(null);
  
  

  useEffect(() => {
    getNosotros();
  }, []);

  const getNosotros = async () => {
    try {
      const res = await axios.get(API_ROUTES.nosotros);
      setNosotros(res.data);
    } catch (error) {
      console.error('Error al obtener datos nosotros:', error);
    }
  };

  const openModal = (nosotro) => {
    setSelectedNosotros(nosotro);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedNosotros(null);
  };



  return (
    <div className='container mb-3 seccionGestionarNosotros'>
      <div className='text-center tituloTablas'><h4>Gestionar Nosotros</h4></div>
      <div className='container mb-3'>
        <div className='row'>
          <div className='col'>
            <Table striped bordered hover responsive="sm">
              <thead className='table-secondary'>
                <tr>
                  <th>Titulo</th>
                  <th>Detalle</th>
                  <th>Imagen</th>
                  <th>Acciones</th>
                </tr>
              </thead>

              <tbody>
                {nosotros.map((nosotro) => (
                  <tr key={nosotro.id}>
                    <td>{nosotro.titulo}</td>
                    <td style={{ maxWidth: '200px', wordWrap: 'break-word', whiteSpace: 'pre-line', textAlign:'justify' }}>
                      {nosotro.detalle.length > 50 ? (
                        <>
                          <div dangerouslySetInnerHTML={{ __html: nosotro.detalle.substring(0, 50) }} />
                          <Button
                            variant='link'
                            onClick={() => openModal(nosotro)}
                          >
                            Ver más
                          </Button>
                        </>
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: nosotro.detalle }} />
                      )}
                    </td>
                   
                    <td>
                      {nosotro.img && (
                        <img
                          src={`${API_ROUTES.nosotrosUploads}${nosotro.img}`}
                          alt={`Imagen de ${nosotro.img}`}
                          style={{ maxWidth: '150px' }}
                        />
                      )}
                    </td>
                    <td className='estilo-accion'>
                      <Link
                        to={`/editnosotros/${nosotro.id}`}
                        className='btn btn-info'
                        style={{ width: '103px' }}
                      >
                        <FaEdit />
                        Editar
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <Modal size="lg" show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title style={{textAlign:'center'}}>{selectedNosotros?.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ whiteSpace: 'pre-line', textAlign:'justify' }}>
          <p dangerouslySetInnerHTML={{ __html: selectedNosotros?.detalle.replace(/<br\s*\/?>/gi, '\n') }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

    </div>

    
  );
};

export default CompShowNosotros;